import React, { Component } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Bradcam from "../components/bradcam"

class ContactUsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contactName: '',
      contactEmail: '',
      contactTitle: '',
      contactMsg: '',
      sendStatus: ''
    }
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    console.log(this.state.contactName, this.state.contactEmail, this.state.contactTitle, this.state.contactMsg)

    const request = new Request('https://ffa0h5g5r2.execute-api.us-east-1.amazonaws.com/mailService/sendmail', 
      {
        method: 'POST', 
        headers: new Headers({'content-type': 'application/json'}),
        mode: 'cors',
        body: JSON.stringify({
          "name": this.state.contactName,
          "email": this.state.contactEmail,
          "title": this.state.contactTitle,
          "msg": this.state.contactMsg,
          "subscribe": false
        }),
      
      }
    );
 
    fetch(request)
      .then(response => {
        if (response.status === 200) {
          this.setState({
            contactName: '',
            contactEmail: '',
            contactTitle: '',
            contactMsg: '',
            sendStatus: 'Message Sent Successful'
          });
          return response.json();
        } else {
          throw new Error('Something went wrong on api server!');
        }
      })
      .then(response => {
        console.debug(response);
        // ...
      }).catch(error => {
        console.error(error);
      });
  }

  updateNameValue(evt) {
    this.setState({
      contactName: evt.target.value
    });
  }

  updateEmailValue(evt) {
    this.setState({
      contactEmail: evt.target.value
    });
  }

  updateTitleValue(evt) {
    this.setState({
      contactTitle: evt.target.value
    });
  }

  updateMsgValue(evt) {
    this.setState({
      contactMsg: evt.target.value
    });
  }

  render() {
    return (
    <Layout>
        <SEO title="Contact Us" />
        <Bradcam siteTitle="Contact Us"></Bradcam>

        <section class="contact-section">
                <div class="container">

        <div class="row">
          <div class="col-12">
              <h2 class="contact-title">Get in Touch</h2>

              <div class="flex-container">
                <div class="col-lg-8">
                  <div class="form-contact contact_form" >
                    <div class="row">
                      <div class="col-sm-6">
                          <div class="form-group">
                              <input value={this.state.contactName} onChange={evt => this.updateNameValue(evt)} class="form-control valid" name="name" id="name" type="text" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Enter your name'" placeholder="Enter your name" />
                          </div>
                      </div>
                      <div class="col-sm-6">
                          <div class="form-group">
                              <input value={this.state.contactEmail} onChange={evt => this.updateEmailValue(evt)} class="form-control valid" name="email" id="email" type="email" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Enter email address'" placeholder="Email" />
                          </div>
                      </div>
                      <div class="col-12">
                          <div class="form-group">
                              <input value={this.state.contactTitle} onChange={evt => this.updateTitleValue(evt)} class="form-control" name="subject" id="subject" type="text" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Enter Subject'" placeholder="Enter Subject" />
                          </div>
                      </div>
                      <div class="col-12">
                          <div class="form-group">
                              <textarea value={this.state.contactMsg} onChange={evt => this.updateMsgValue(evt)} class="form-control w-100" name="message" id="message" cols="30" rows="9" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Enter Message'" placeholder="Enter your message"></textarea>
                          </div>
                      </div>
                    </div>
                    <div class="form-group mt-3">
                        <div class="contact-flex-container">
                          <button onClick={this.handleClick} class="button button-contactForm boxed-btn">Send</button>
                          <div class="msg-status">{this.state.sendStatus}</div>
                        </div>
                    </div>
                    
                  </div>
                </div>
                <div class="col-lg-3 offset-lg-1">
                  <div class="media contact-info">
                      <span class="contact-info__icon"><i class="ti-user"></i></span>
                      <div class="media-body">
                          <h3>+1 312 593 2173</h3>
                          <p>Mon to Fri 9am to 6pm</p>
                      </div>
                  </div>
                  <div class="media contact-info">
                      <span class="contact-info__icon"><i class="ti-email"></i></span>
                      <div class="media-body">
                          <h3>help@clouduprealty.com</h3>
                          <p>Send us your query anytime!</p>
                      </div>
                  </div>
              </div>
            </div>

          </div>
        </div>

      </div>
      </section>




      </Layout>
    )
  }
}
  


export default ContactUsPage;
